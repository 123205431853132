import {Pagination} from "../common/commons.types";
import {SubscribePayload, Subscription, PaymentIntent, SubscriberSubscription} from "./payment-types";
import axios from "axios";
import {SetHeaderToken} from "../common/misc-util";

export class PaymentApi {
    public constructor(private apiBaseUri: string) {
    }

    async getSubscriptionList(codeBouton: string, limit: number, offset: number, token?: string): Promise<Pagination<Subscription>> {
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula?module=Planner&limit=${limit}&offset=${offset}&status=active`, SetHeaderToken(token));
        return response.data
    }

    async createSubscription(codeBouton: string, id: string, body: SubscribePayload, token?: string): Promise<PaymentIntent> {
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}/subscription`, body, SetHeaderToken(token));
        return response.data
    }

    async getSubscription(codeBouton: string, id: string, token?: string): Promise<SubscriberSubscription> {
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriber/me/subscription/${id}`, SetHeaderToken(token))
        return response.data
    }

    async getSubscriberSubscriptionsList(codeBouton: string, limit: number, offset: number, token?: string): Promise<Pagination<SubscriberSubscription>> {
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriber/me/subscription?module=Planner&limit=${limit}&offset=${offset}`, SetHeaderToken(token))
        return response.data
    }

    async deleteSubscriberSubscription(codeBouton: string, id: string, token?: string): Promise<SubscriberSubscription> {
        const response = await axios.delete(`${this.apiBaseUri}/${codeBouton}/subscriber/me/subscription/${id}`, SetHeaderToken(token))
        return response.data
    }
}