
const planner = 'Planner';
export const active = 'active';
export const pending = 'pending';
export const canceled = 'canceled';
export const error = 'error';
const STRIPE = 'STRIPE';

export interface Subscription {
    id: string;
    name: string;
    description: string;
    subscriptionConditions: string;
    unsubscriptionConditions: string;
    otherSubscriptionConditions: string;
    subscriptionFees?: number|null;
    plans: SubscriptionPlan[]
    services: ServiceId[]
    module: typeof planner|null;
    display: boolean;
    subscriptions: SubscriptionCustomer[]
    status: typeof active|null
    paymentType: typeof STRIPE|null;
    fromName?: string|null;
    locale?: string|null;
    currency: string;
}

export interface SubscriptionPlan {
    id: string;
    price?: number|null;
    tacitRenewal?: boolean|null;
    active: boolean|null;
    numberOfMonths: number
}

interface ServiceId {
    id: string
}

interface SubscriptionCustomer {
    id: string;
    subscriber: Subscriber
    status: typeof active;
    plan: SubscriptionPlan
}

interface Subscriber {
    id: string
    email: string
    locale: string
    name?: string|null
    phone?: string|null
}

export interface SubscribePayload {
    subscriberId: string
    email: string
    planId: string
    locale: string
    firstName?: string|null
    lastName?: string|null
    phone?: string|null
}

export type SubscriberSubscriptionStatus = typeof pending|typeof active|typeof canceled|typeof error;

export interface SubscriberSubscription {
    id: string;
    name: string;
    createdAt: string;
    activatedAt: string;
    status: SubscriberSubscriptionStatus
    plan: SubscriptionPlan;
    services: ServiceId[];
    currentPeriodEnd?: string|null;
    subscriptionFormulaId: string;
}

export interface PaymentIntent {
    clientSecret: string;
    subscriberName?: string;
    subscription: SubscriberSubscription
}