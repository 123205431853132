import { FC } from "react";
import { AccountPageWrapper, LoadMore } from "../components/common";
import { routeUserAccount } from "../../../routes";
import { generatePath, useNavigate } from "react-router";
import { useAuthStore } from "../../authentification/auth-store";
import { useIntl } from "react-intl";
import { useConfirm } from "../../../common/providers/confirm-provider";
import { useCodeBouton } from "../../../common/providers/code-bouton-provider";
import { useAccountStore } from "../account-store";
import { useConfigurationStore } from "../../../configuration/configuration-store";
import { useServicesStore } from "../../../service/services-store";
import { SubscriptionDisplayCard } from "../components/subscription-display-card";
import { usePaymentStore } from "../../../subscription/payment-store";
import { useToaster } from "@linkeo.com/ui-lib-react";

export const SubscriptionsListingPage: FC = () => {

    const intl = useIntl();
    const { logOut } = useAuthStore();
    const { openConfirm } = useConfirm();
    const config = useConfigurationStore();
    const navigate = useNavigate();
    const toast = useToaster();
    const { services } = useServicesStore();
    const codeBouton = useCodeBouton();
    const { subscriptionsCount, subscriptions, loadSubscriptions, resetSubscriptions } = useAccountStore();
    const { subscriptionsList, cancelSubscription } = usePaymentStore()

    const onLogoutClick = async () => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'accountPageLogoutConfirmation',
                defaultMessage: "Êtes-vous sûr(e) ?"
            }), title: intl.formatMessage({ id: 'accountPageLogoutText', defaultMessage: "Déconnexion" })
        });
        logOut();
    }

    const onSubscriptionCancelClick = async (id: string) => {
        await openConfirm({
            content: intl.formatMessage({
                id: 'subscriptionListingPageCancelSubscriptionConfirmText',
                defaultMessage: 'Êtes-vous sûr(e) de vouloir arrêter le renouvellement de votre formule d\'abonnement ?'
            }), title: intl.formatMessage({
                id: 'subscriptionListingPageCancelSubscriptionButtonLabel',
                defaultMessage: 'Annuler le renouvellement'
            })
        });
        try {
            await cancelSubscription(id);
            resetSubscriptions();
            navigate(generatePath(routeUserAccount, { codeBouton, locale: intl.locale }))
        } catch {
            toast(intl.formatMessage({ id: 'accountEditError', defaultMessage: 'Une erreur est survenue, merci de réessayer utlérieurement' }))
        }
    }

    const getFrequencyTitle = (number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({ id: 'subscriptionFrequencyTitleYearly', defaultMessage: '1 an' })
            case 3:
                return intl.formatMessage({ id: 'subscriptionFrequencyTitleQuaterly', defaultMessage: 'Trimestriel' })
            case 1:
            default:
                return intl.formatMessage({ id: 'subscriptionFrequencyTitleMonthly', defaultMessage: 'Mensuel' })
        }
    }

    const getFrequencySubtitle = (number: number): string => {
        switch (number) {
            case 12:
                return intl.formatMessage({ id: 'yearly', defaultMessage: 'an' })
            case 3:
                return intl.formatMessage({ id: 'quaterly', defaultMessage: 'trimestre' })
            case 1:
            default:
                return intl.formatMessage({ id: 'monthly', defaultMessage: 'mois' })
        }
    }

    return <AccountPageWrapper
        backLinkUrl={generatePath(routeUserAccount, { codeBouton, locale: intl.locale })}
        onLogoutClick={onLogoutClick}
        pageTitle={intl.formatMessage({
            id: "accountSubscriptionsPageTitle",
            defaultMessage: 'Vos abonnements'
        })}>
        <LoadMore total={subscriptionsCount || 0} count={subscriptions.length} loadMore={loadSubscriptions}>
            {subscriptions.map((subscription) => {
                return <SubscriptionDisplayCard
                    key={subscription.id}
                    name={subscription.name + ' ' + getFrequencyTitle(subscription.plan.numberOfMonths)}
                    date={subscription.createdAt}
                    status={subscription.status}
                    currency={config.currency}
                    price={subscription.plan.price || 0}
                    frequency={getFrequencySubtitle(subscription.plan.numberOfMonths)}
                    services={subscription.services.map(s => services.find(el => el.id === s.id)?.title)}
                    subscriptionConditions={subscriptionsList.find(s => s.id === subscription.subscriptionFormulaId)?.subscriptionConditions}
                    unsubscriptionConditions={subscriptionsList.find(s => s.id === subscription.subscriptionFormulaId)?.unsubscriptionConditions}
                    otherSubscriptionConditions={subscriptionsList.find(s => s.id === subscription.subscriptionFormulaId)?.otherSubscriptionConditions}
                    onSubscriptionCancelClick={() => onSubscriptionCancelClick(subscription.id)}
                    currentPeriodEnd={subscription.currentPeriodEnd}
                />
            })}
        </LoadMore>
    </AccountPageWrapper>
}